import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import alertMessageReducer from '../features/messages/alertMessageSlice'
import inventoryDocumentReducer from '../features/storage/inventory_documents/inventoryDocumentsSlice'
import usersManagementReducer from '../features/storage/admin/management/usersManagementSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alertMessage: alertMessageReducer,
    inventoryDocuments: inventoryDocumentReducer,
    usersManagement: usersManagementReducer
  },
})
