const apiErrorMessageParser = (error) => {
    let errorMessage;
    // console.log(error);
    // console.log(error.message);
    // console.log(error.response.data);
    // console.log(error.response.status);

    if (error.response.status && error.response.status === 404) {
        errorMessage = error.message;
        return errorMessage;
    }

    if(error.response.data && error.response.data.message){
        errorMessage = error.response.data.message;
        return errorMessage;
    }

    // if (error.response.status && error.response.status === 400) {
    //     if (error.response.data && error.response.data.message) {
    //         errorMessage = error.response.data.message;
    //         return errorMessage;
    //     }
    // }

    // if (error.response.status && error.response.status === 401) {
    //     if (error.response.data && error.response.data.message) {
    //         errorMessage = error.response.data.message;
    //         return errorMessage;
    //     }
    // }

    return error.toString();
};

export default apiErrorMessageParser;
