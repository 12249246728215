import loggerAxios  from "./../../../../utils/loggerAxios"
import { authConfig } from "../../../../utils/authorizationUtils"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "erp/admin/management"

// get Users
const getUsers = async (queryParams) => {
    const queryString = queryParams.reduce(function(queryString, param) {
        if(param.value !== undefined){
            queryString += param.key + "=" + param.value + "&"
        }
        return queryString;
    }, '')
    const response = await loggerAxios.get(
        API_URL + "/users?" + queryString.slice(0, -1),
        authConfig
    )

    return response.data
}

// Send Password Token
const sendPasswordToken = async (id) => {
    const response = await loggerAxios.get(API_URL + "/user/resetPasswordToken?id=" + id, authConfig)

    return response.data
}

// Reset Password using token
const resetPassword = async (data) => {
    const response = await loggerAxios.post(API_URL + "/user/resetPassword", data, authConfig)

    return response.data
}

const usersManagementService = {
    getUsers,
    sendPasswordToken,
    resetPassword
}

export default usersManagementService