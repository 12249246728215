import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import inventoryDocumentsService from "./inventoryDocumentsService"
import usersService from "../../users/usersService"
import apiErrorMessageParser from "../../../utils/apiErrorParser"

const initialState = {
    ecomClients: [],
    storageClients: [],
    availableReceptions: [],
    inventoryDocuments: [],
    inventoryDocumentsPagination: {
        current_page: 1,
        last_page: 1,
        per_page: 0,
    },
    inventoryDocument: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    errorList: [],
    docsErrorMessage: "",
}

export const getEcomClients = createAsyncThunk(
    "users/getEcomClients",
    async (_, thunkAPI) => {
        try {
            return await usersService.getEcomClients()
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getStorageClients = createAsyncThunk(
    "users/getStorageClients",
    async (_, thunkAPI) => {
        try {
            return await usersService.getStorageClients()
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAvailableReceptions = createAsyncThunk(
    "inventoryDocuments/getAvailableReceptions",
    async (groupId, thunkAPI) => {
        try {
            return await inventoryDocumentsService.getAvailableReceptions(groupId)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Create new document
export const createDocument = createAsyncThunk(
    "inventoryDocuments/create",
    async (documentData, thunkAPI) => {
        try {
            return await inventoryDocumentsService.createDocument(documentData)
        } catch (error) {
            const message = apiErrorMessageParser(error)
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all inventory documents paginated
export const getInventoryDocuments = createAsyncThunk(
    "inventoryDocuments/getInventoryDocuments",
    async (queryParams, thunkAPI) => {
        try {
            return await inventoryDocumentsService.getInventoryDocuments(queryParams)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get  inventory document
export const getInventoryDocument = createAsyncThunk(
    "inventoryDocuments/getInventoryDocument",
    async (id, thunkAPI) => {
        try {
            return await inventoryDocumentsService.getInventoryDocument(id)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const inventoryDocumentSlice = createSlice({
    name: "inventoryDocument",
    initialState,
    reducers: {
        reset: (state) => initialState,
        stateReset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = "";
            state.errorList = [];
            state.docsErrorMessage = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEcomClients.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEcomClients.fulfilled, (state, action) => {
                state.isLoading = false
                state.ecomClients = Object.values(action.payload.data)
            })
            .addCase(getEcomClients.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getStorageClients.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getStorageClients.fulfilled, (state, action) => {
                state.isLoading = false
                state.storageClients = Object.values(action.payload.data)
            })
            .addCase(getStorageClients.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getAvailableReceptions.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAvailableReceptions.fulfilled, (state, action) => {
                state.isLoading = false
                state.availableReceptions = Object.values(action.payload.data)
            })
            .addCase(getAvailableReceptions.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getInventoryDocument.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInventoryDocument.fulfilled, (state, action) => {
                state.isLoading = false
                state.inventoryDocument = action.payload.data
            })
            .addCase(getInventoryDocument.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getInventoryDocuments.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInventoryDocuments.fulfilled, (state, action) => {
                state.isLoading = false
                state.inventoryDocuments = Object.values(action.payload.data)
                state.inventoryDocumentsPagination = {current_page: action.payload.current_page, last_page: action.payload.last_page, per_page: action.payload.per_page}
            })
            .addCase(getInventoryDocuments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(createDocument.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createDocument.fulfilled, (state) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(createDocument.rejected, (state, action) => {
                state.docsErrorMessage = ""
                state.isLoading = false
                state.isError = true
                state.message = action.payload.message ?? action.payload
                state.errorList = action.payload.errors ?? []

                if(action.payload.errors && Object.keys(action.payload.errors).some(i => { return i.startsWith('docs') })){
                    state.docsErrorMessage = Object.keys(action.payload.errors).reduce(function(acc, key) {
                        if(key.startsWith('docs'))
                        acc += action.payload.errors[key][0];
                        return acc;
                    }, '')
                }
            })
        // .addCase(getTickets.pending, (state) => {
        //   state.isLoading = true
        // })
        // .addCase(getTickets.fulfilled, (state, action) => {
        //   state.isLoading = false
        //   state.isSuccess = true
        //   state.tickets = action.payload
        // })
        // .addCase(getTickets.rejected, (state, action) => {
        //   state.isLoading = false
        //   state.isError = true
        //   state.message = action.payload
        // })
        // .addCase(getTicket.pending, (state) => {
        //   state.isLoading = true
        // })
        // .addCase(getTicket.fulfilled, (state, action) => {
        //   state.isLoading = false
        //   state.isSuccess = true
        //   state.ticket = action.payload
        // })
        // .addCase(getTicket.rejected, (state, action) => {
        //   state.isLoading = false
        //   state.isError = true
        //   state.message = action.payload
        // })
    },
})

export const { reset, stateReset } = inventoryDocumentSlice.actions
export default inventoryDocumentSlice.reducer
