import loggerAxios from "./../../utils/loggerAxios"
import { authConfig } from "../../utils/authorizationUtils"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "erp/users/"

// get ecom clients
const getEcomClients = async () => {
    const response = await loggerAxios.get(
        API_URL + "ecomClients",
        authConfig
    )

    return response.data
}

// get storage clients
const getStorageClients = async () => {
    const response = await loggerAxios.get(
        API_URL + "storageClients",
        authConfig
    )

    return response.data
}

const userService = {
    getEcomClients,
    getStorageClients,
}

export default userService
