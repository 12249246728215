import Cookies from "universal-cookie"
const cookies = new Cookies()

export const token = cookies.get("X-MHMAPP-X-ACCESS-TOKEN")

export const authConfig = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}

export const authConfigMultipart = {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
        'Accept': 'application/json',
    },
}