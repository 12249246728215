import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../features/auth/authSlice";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import { setErrorMessage } from "../features/messages/alertMessageSlice";
import AlertMessage from "../components/AlertMessage";

import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCol,
    MDBCardBody,
    MDBTabsPane,
    MDBTabsContent,
    MDBCheckbox,
    MDBInput,
    MDBBtn,
} from "mdb-react-ui-kit";

const Login = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const { email, password } = formData;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    );
    const { loggedIn, checkingStatus } = useAuthStatus();

    useEffect(() => {
        if (isError) {
            toast.error(message);
            dispatch(setErrorMessage(message));
        }

        // Redirect when logged in
        if(isSuccess)
        {
            window.location.reload();
        }
        if (loggedIn) {
            navigate("/");
        }

        return () => {
            dispatch(reset())
        };
    }, [isError, isSuccess, loggedIn, message, navigate, dispatch]);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const userData = {
            email,
            password,
        };

        dispatch(login(userData));
    };

    // if (isLoading || checkingStatus) {
    //   return <Spinner />
    // }

    return (
        <MDBContainer className="pt-5">
            {isLoading || checkingStatus ? <Spinner /> : <></>}
            <MDBRow className="d-flex justify-content-center">
                <MDBCol md="6">
                    <MDBCard>
                        <MDBCardBody className="p-4">
                        <AlertMessage></AlertMessage>
                            <MDBTabsContent>
                                <MDBTabsPane show={true}>
                                    <form onSubmit={onSubmit}>
                                        <MDBInput
                                            className="mb-4"
                                            type="email"
                                            id="loginName"
                                            name="email"
                                            value={email}
                                            onChange={onChange}
                                            placeholder="Enter your email"
                                            required
                                            label="Enter your email"
                                        />

                                        <MDBInput
                                            className="mb-4"
                                            type="password"
                                            id="loginPassword"
                                            name="password"
                                            value={password}
                                            onChange={onChange}
                                            placeholder="Enter your password"
                                            required
                                            label="Enter your password"
                                        />

                                        <MDBRow className="mb-4">
                                            <MDBCol
                                                md="6"
                                                className="d-flex justify-content-center"
                                            >
                                                <MDBCheckbox
                                                    className=" mb-3 mb-md-0"
                                                    defaultChecked
                                                    label=" Remember me"
                                                />
                                            </MDBCol>

                                            <MDBCol
                                                md="6"
                                                className="d-flex justify-content-center"
                                            >
                                                <Link
                                                    to="/forgot-password"
                                                >
                                                    Forgot password?
                                                </Link>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBBtn
                                            type="submit"
                                            block
                                            className="mb-4"
                                        >
                                            Sign in
                                        </MDBBtn>
                                    </form>
                                </MDBTabsPane>
                            </MDBTabsContent>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Login;
